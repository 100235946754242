import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { UX2, constants, components } from '@wsb/guac-widget-core';
import Carousel from '@wsb/guac-widget-shared/lib/components/Carousel';
import { clamp, isEqual } from 'lodash';
import { getLogosDataAid, getInvisibleDataAid } from '../constants/dataAids';

const { PREVIEW } = constants.renderModes;
const { MOBILE_RENDER_DEVICE } = constants.renderDevices;
const { Link } = components;
const { GRAYSCALE, NONE } = constants.imageFilters;

const PrevArrow = ({ prevSlide, styles }) => {
  return (
    <UX2.Element.Icon icon='chevronLeft' onClick={ prevSlide } style={ styles } data-edit-interactive />
  );
};
PrevArrow.propTypes = {
  prevSlide: PropTypes.func,
  styles: PropTypes.object
};

const NextArrow = ({ nextSlide, styles }) => {
  return (
    <UX2.Element.Icon
      icon='chevronRight'
      onClick={ nextSlide }
      style={ styles }
      data-edit-interactive
    />
  );
};
NextArrow.propTypes = {
  nextSlide: PropTypes.func,
  styles: PropTypes.object
};

class CarouselComponent extends Component {
  constructor() {
    super(...arguments);

    this.containerRef = createRef();
    this.carouselRef = createRef();

    this.state = {
      exceedsContainer: true,
      allImagesLoaded: false
    };

    this.imagesLoaded = 0;
  }

  generateStyles() {
    return {
      invisibleCarousel: {
        position: 'absolute',
        visibility: 'hidden',
        display: 'flex'
      },
      fixedCarousel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      carouselContainer: {
        '@xs-only': {
          '.carousel-viewport': {
            maxWidth: '80%'
          }
        },
        '@md': {
          '.carousel-viewport': {
            maxWidth: '90%'
          }
        },
        'position': 'relative'
      },
      carousel: {
        viewport: {
          margin: '0 auto'
        },
        slide: {
          opacity: 1
        }
      },
      imgCol: {
        '@xs-only': {
          height: '80px',
          paddingHorizontal: 'small'
        },
        'paddingHorizontal': 'medium'
      },
      img: {
        maxHeight: '100%',
        maxWidth: '100%'
      },
      arrow: {
        color: 'highContrast',
        width: 48,
        height: 48,
        cursor: 'pointer',
        position: 'absolute',
        top: 30,
        ['@xs-only']: {
          width: 32,
          height: 32
        }
      }
    };
  }

  componentDidUpdate(prevProps) {
    const { slides, renderMode, autoplay, showArrows } = this.props;

    // stabilize center index
    if (
      showArrows !== prevProps.showArrows ||
      autoplay !== prevProps.autoplay ||
      !isEqual(slides, prevProps.slides) ||
      this.state.exceedsContainer
    ) {
      if (typeof window !== 'undefined') {
        window.dispatchEvent(new Event('resize'));
      }
    }

    if (slides.length !== prevProps.slides.length) {
      this.onImageLoad();
    }

    if (renderMode !== prevProps.renderMode) {
      this.imagesLoaded = 0;
    }
  }

  onImageLoad = () => {
    const { slides } = this.props;

    this.imagesLoaded = clamp(this.imagesLoaded + 1, 0, slides.length);

    if (
      this.imagesLoaded === slides.length &&
      this.containerRef.current &&
      this.carouselRef.current
    ) {
      this.setState({
        exceedsContainer:
          this.carouselRef.current.clientWidth > this.containerRef.current.clientWidth,
        allImagesLoaded: true
      });
    }
  };

  renderLogos = (invisible = false) => {
    const { slides = [], isBlackAndWhite } = this.props;
    const styles = this.generateStyles();

    const renderLogo = (dataAid, image) => (
      <UX2.Element.Image.Logo
        data-aid={ dataAid }
        style={ styles.img }
        imageData={{ ...image, outputHeight: 100, filter: isBlackAndWhite ? GRAYSCALE : NONE }}
        onLoad={ invisible ? this.onImageLoad : null }
      />
    );

    return slides.map(({ image, externalLink = {} }, i) => {
      const dataAid = invisible ? getInvisibleDataAid(i) : getLogosDataAid(i);
      const dataRouteProps = invisible
        ? {}
        : {
          'data-field-id': 'logoImages.image',
          'data-field-route': `/logoImages/${i}`
        };
      return (
        <UX2.Element.Block { ...dataRouteProps } style={ styles.imgCol } key={ i }>
          { !invisible ? (
            <Link linkData={ externalLink }> { renderLogo(dataAid, image) } </Link>
          ) : (
            renderLogo(dataAid, image)
          ) }
        </UX2.Element.Block>
      );
    });
  };

  render() {
    const { slides = [], showArrows, autoplay, autoplayDelay, renderMode, viewDevice } = this.props;
    const { exceedsContainer, allImagesLoaded } = this.state;

    const isMobilePreview = renderMode === PREVIEW && viewDevice === MOBILE_RENDER_DEVICE;
    const styles = this.generateStyles();
    const controls = showArrows
      ? [
        {
          component: PrevArrow,
          props: {
            styles: {
              ...styles.arrow,
              left: 0
            }
          }
        },
        {
          component: NextArrow,
          props: {
            styles: {
              ...styles.arrow,
              right: 0
            }
          }
        }
      ]
      : [];

    return (
      <div ref={ this.containerRef }>
        <div ref={ this.carouselRef } style={ styles.invisibleCarousel }>
          { this.renderLogos(true) }
        </div>

        <UX2.Element.Block
          style={{
            ...styles.carouselContainer,
            visibility: allImagesLoaded ? 'visible' : 'hidden'
          }}
        >
          { exceedsContainer ? (
            <Carousel
              arrows={ false }
              dots={ false }
              autoplay={ autoplay }
              infinite={ autoplay || showArrows }
              autoplaySpeed={ Number(autoplayDelay) * 1000 }
              viewportWidth={ isMobilePreview ? '80%' : null }
              initialSlide={ Math.floor(slides.length / 2) }
              transitionDuration={ 200 }
              clickToNavigate={ false }
              lazyLoad={ false }
              controls={ controls }
              style={{ ...styles.carousel }}
            >
              { this.renderLogos() }
            </Carousel>
          ) : (
            <UX2.Element.Block style={ styles.fixedCarousel }>{ this.renderLogos() }</UX2.Element.Block>
          ) }
        </UX2.Element.Block>
      </div>
    );
  }
}

CarouselComponent.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      externalLink: PropTypes.oneOfType([
        PropTypes.shape({
          linkId: PropTypes.string.isRequired
        }),
        PropTypes.shape({
          target: PropTypes.string,
          url: PropTypes.string.isRequired
        })
      ])
    })
  ).isRequired,
  renderMode: PropTypes.string,
  viewDevice: PropTypes.string,
  showArrows: PropTypes.bool,
  autoplay: PropTypes.bool,
  autoplayDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isBlackAndWhite: PropTypes.bool
};

export default CarouselComponent;
