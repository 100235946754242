/* eslint-disable id-length */
import keyMirror from 'keymirror';

export const dataAids = keyMirror({
  LOGOS_OPTIONAL_SECTION_TITLE_RENDERED: null,
  LOGOS_SECTION_TITLE_RENDERED: null,
  LOGOS_GRID_RENDERED: null,
  LOGO_ROWS_CONTAINER: null,
  LOGO_ROW_WRAPPER: null,
  LOGOS_ZERO_STATE: null
});

export const getLogosDataAid = num => {
  return `LOGOS_IMAGE${num}_RENDERED`;
};

export const getInvisibleDataAid = num => {
  return `LOGOS_INVISIBLE${num}_RENDERED`;
};
